export default {
  "menu.welcome": "Welcome",
  "menu.list.company-management": "Company Management",
  "menu.list.store-management": "Store Management",
  "menu.list.store-management.joined-stores": "Joined Stores",
  "menu.list.store-management.all-stores": "All Stores",
  "menu.list.store-management.store-funds": "Store Funds",
  "menu.list.user-management": "User Management",
  "menu.list.user-management.all-users": "All Users",
  "menu.account.center": "Account Center",
  "menu.account.settings": "Account Settings",
  "menu.account.trigger": "Trigger Error",
  "menu.account.changePassword": "Change Password",
  "menu.account.myCompany": "My Company",
  "menu.account.logout": "Logout",
  "menu.order": "Order",
  "menu.payment": "Payment",
  "menu.payment.line-items": "Line Items",
  "menu.payment.bill-list": "Bill List",
  "menu.product": "Product",
  "menu.product.list": "Product List",
  "menu.order.detail": "Order Detail",
  "menu.site-message": "Message",
  "menu.payment.billDetail": "Bill Detail",
  "menu.app-settings": "App Settings",
  "menu.payment.receipt": "Receipt",
  "menu.internal": "Internal Management",
  "menu.internal.customer-user": "Customer Management",
  "menu.internal.customer-user-detail": "Customer Detail",
  "menu.internal.ban-customer-user": "Ban Customer",
  "menu.internal.manual-order": "Manual Order",
  "menu.internal.customer-order": "Customer Order",
  "menu.internal.customer-video": "Customer Video",
  "menu.internal.customer-video-audit": "Video Audit",
  "menu.internal.paypal-record": "PayPal Record",
  "menu.internal.mass-payout": "Mass Payout",
  "menu.internal.google-merchant-center": "Google Merchant Center",
  "menu.internal.stripe-flow": "Stripe Flow",
  "menu.internal.algo-feed": "Deal Feed",
};
